/* eslint-disable class-methods-use-this */
/**
 * Luma Group
 *
 * @author Robuust
 * @author Jurriën Peters <jurrien@robuust.digital>
 * @author Bob Olde Hampsink <bob@robuust.digital>
 */

import { Controller } from '@hotwired/stimulus';

/**
 * Form controller.
 */
export default class extends Controller {
  static targets = ['form', 'label', 'success'];

  /**
   * Submit the form
   *
   * @param {Event} e
   */
  async submit(e) {
    e.preventDefault();
    const response = await fetch(window.location.href, {
      method: 'POST',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
      },
      body: new FormData(this.element),
    });
    const result = await response.json();

    if ('message' in result || 'member' in result) {
      this.formTarget.classList.add('hidden');
      this.successTarget.classList.remove('hidden');
    }
  }

  /**
   * Change label of the file input with the filename.
   *
   * @param {Event} e
   */
  fileInput(e) {
    const files = Array.from(e.target.files) ?? [];
    this.labelTarget.textContent = files.length ? files.map((f) => f.name).join(', ') : e.params.label;
  }
}
