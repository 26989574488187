/* eslint-disable class-methods-use-this */
/**
 * Luma Group
 *
 * @author Robuust
 * @author Jurriën Peters <jurrien@robuust.digital>
 */

import { Controller } from '@hotwired/stimulus';

const { mapkit } = window;

/**
 * Controller for jobs.
 */
export default class extends Controller {
  /**
   * @return {Array}
   */
  static targets = ['list', 'map', 'postcode', 'alert', 'email'];

  /**
  * @return {Array}
  */
  static classes = ['active'];

  /**
   * @return {Object}
   */
  static values = {
    url: String,
    token: String,
    tokenName: String,
  };

  /**
   * @var {Map}
   */
  map;

  /**
   * @var {Map}
   */
  annotations = new Map();

  /**
   * @var {Map}
   */
  circleOverlays = new Map();

  /**
   * Initialize map.
   */
  initialize() {
    mapkit.init({
      authorizationCallback(done) {
        done(import.meta.env.VITE_MAPKIT_TOKEN);
      },
    });
  }

  /**
   * Connect map.
   *
   * @param {HTMLElement} target
   */
  async mapTargetConnected(target) {
    this.map = new mapkit.Map(target);

    // Always set the map's region to The Netherlands
    const region = new mapkit.CoordinateRegion(
      new mapkit.Coordinate(52.1326, 5.2913),
      new mapkit.CoordinateSpan(4.5, 4.5),
    );
    this.map.region = region;

    // Get markers
    const response = await fetch(this.urlValue);
    const result = await response.json();

    // Set markers
    const markers = [];
    for (let i = 0; i < result.length; i += 1) {
      const job = result[i];
      const coords = new mapkit.Coordinate(job.latitude, job.longitude);

      const annotation = this.getAnnotation(coords, [job], {
        data: { ...job },
        anchorOffset: new DOMPoint(0, -8),
      });

      markers.push(annotation);
    }

    this.map.annotationForCluster = (clusterAnnotation) => this.getAnnotation(
      clusterAnnotation.coordinate,
      clusterAnnotation.memberAnnotations,
      { anchorOffset: new DOMPoint(0, -16) },
    );

    this.map.showItems(markers, {
      minimumSpan: new mapkit.CoordinateSpan(0.5, 0.5),
    });
  }

  /**
   * Destroy map.
   */
  mapTargetDisconnected() {
    if (this.map !== undefined) {
      this.map.destroy();
    }
  }

  /**
   * Request submit.
   *
   * @param {Event} e
   */
  submit(e) {
    e.target.form.requestSubmit();
  }

  /**
   * Geocode.
   *
   * @param {Event} e
   */
  async geocode(e) {
    // Add pulse animation
    e.target.classList.add('animate-pulse');

    // Get current GPS position
    const position = await this.getPosition();

    // Send coords to server to retrieve current country
    const coordinates = new mapkit.Coordinate(position.coords.latitude, position.coords.longitude);
    const address = await this.getAddress(coordinates);

    // Set postcode in postcode field
    this.postcodeTarget.value = address?.results[0]?.postCode;

    // Remove pulse animation
    e.target.classList.remove('animate-pulse');
  }

  /**
   * Get position.
   *
   * @return {Promise}
   */
  getPosition() {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject);
    });
  }

  /**
   * Get address from coordinates.
   *
   * @param {Coordinate} coordinates
   *
   * @return {Promise}
   */
  getAddress(coordinates) {
    const geocoder = new mapkit.Geocoder();
    return new Promise((resolve, reject) => {
      geocoder.reverseLookup(coordinates, (error, data) => {
        if (error) {
          reject(error);
        } else {
          resolve(data);
        }
      });
    });
  }

  /**
   * Create annotation.
   *
   * @param {Coordinate} coords
   * @param {Array} markers
   * @param {Object} options
   *
   * @returns {Annotation}
   */
  getAnnotation(coordinate, markers, options = {}) {
    const key = `${coordinate.latitude.toFixed(7)},${coordinate.longitude.toFixed(7)}`;
    const annotation = new mapkit.Annotation(coordinate, this.getMarker.bind(this, markers), {
      animates: false,
      clusteringIdentifier: key,
      ...options,
      callout: { calloutElementForAnnotation: this.getCallout.bind(this) },
    });

    if (this.hasActiveClass) {
      annotation.addEventListener('select', () => {
        annotation.element.classList.add(this.activeClass);
      });

      annotation.addEventListener('deselect', () => {
        annotation.element.classList.remove(this.activeClass);
      });
    }

    this.annotations.set(key, annotation);

    if (this.circleOverlays.has(key)) {
      this.map.removeOverlay(this.circleOverlays.get(key));
    }

    const radius = 2500 + (1 - Math.exp(-markers.length / 10)) * 5000;
    const circle = new mapkit.CircleOverlay(coordinate, radius);
    circle.style = new mapkit.Style({ lineWidth: 0, fillColor: '#306852', fillOpacity: 0.3 });
    this.map.addOverlay(circle);
    this.circleOverlays.set(key, circle);

    return annotation;
  }

  /**
   * Create marker.
   *
   * @param {Array} jobs
   *
   * @return {Element}
   */
  getMarker(jobs) {
    const element = document.createElement('div');
    element.className = 'job-marker';

    if (jobs.length > 1) {
      element.textContent = jobs.length;
    }

    return element;
  }

  /**
   * Create callout.
   *
   * @param {Object} annotation
   */
  getCallout(annotation) {
    let data = [annotation.data];
    if (annotation.memberAnnotations) {
      data = annotation.memberAnnotations.map((a) => a.data);
    }

    const url = new URL('/actions/jobs/jobs/callout', window.location.origin);
    url.search = window.location.search;
    data.forEach((job) => {
      url.searchParams.append('ids[]', job.id);
    });

    const element = document.createElement('turbo-frame');
    element.id = 'callout';
    element.src = url.toString();

    return element;
  }

  /**
   * Hover target class.
   *
   * @param {Event} e
   */
  hover(e) {
    const target = e.target.closest('[data-keys]');
    const active = e.type === 'mouseenter' || target.classList.contains(this.activeClass);

    this.annotations.forEach((a, key) => {
      a.element.classList.toggle(
        this.activeClass,
        JSON.parse(target.dataset.keys).includes(key) && active,
      );
    });
  }

  /**
   * Toggle email field availability in submit.
   *
   * @param {Event} e
   */
  email(e) {
    if (this.hasEmailTarget) {
      this.emailTarget.disabled = e.params.disabled;
    }
  }

  /**
   * Subscribe for job alert.
   *
   * @param {Event} e
   */
  async alert(e) {
    const { target, submitter } = e;
    if (submitter.value) {
      e.preventDefault();

      // Set form body
      const body = new FormData(target);
      body.append(this.tokenNameValue, this.tokenValue);
      body.append(submitter.name, submitter.value);

      const response = await fetch(window.location.href, {
        method: 'POST',
        credentials: 'include',
        headers: {
          Accept: 'application/json',
        },
        body,
      });
      const result = await response.json();

      if (result.success) {
        this.alertTarget.innerHTML = '<span class="font-medium">Vacature-alert ingesteld!</span><br>Afmelden kan via de link onderaan de e-mail.';
        submitter.parentNode.remove();
      }
    }
  }

  /**
   * Scroll list and window to top.
   */
  scrollToTop() {
    const scrollConfig = { top: 0, behavior: 'smooth' };
    this.listTarget.scrollTo(scrollConfig);
    window.scrollTo(scrollConfig);
  }
}
